<template>
  <div class="queryView">
    <div class="queryView-conditions">
      <a-form layout="inline" class="form-label-4" :form="form" ref="formRef">
        <a-form-item label="条线">
          <a-select
            class="form-control"
            v-decorator="['lineCode']"
            :options="opsLineList"
            placeholder="请选择条线"
            @change="onSelectOpsLine"
          ></a-select>
        </a-form-item>
        <a-form-item label="类别">
          <a-select
            class="form-control"
            :options="opsTypeList"
            v-decorator="['typeCode']"
            placeholder="请选择类别"
          ></a-select>
        </a-form-item>
        <a-form-item label="服务名称">
          <a-input v-decorator="['opsName']" placeholder="请输入服务名称" />
        </a-form-item>
        <a-form-item>
          <div class="condition-btns">
            <a-button type="primary" @click="doQuery(1)">查询</a-button>
            <a-button type="default" @click="resetAndQuery">重置</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div class="queryView-actions">
      <a-button type="primary" @click="addRow">新增</a-button>
    </div>
    <div class="queryView-main">
      <a-table
        class="queryView-table"
        :dataSource="dataSource"
        :columns="columns"
        :rowKey="getRowKey"
        :loading="loading"
        :pagination="{
          current: pageNo,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          showQuickJumper: true,
        }"
        :scroll="{
          x: 1300,
        }"
        @change="handleTableChange"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, getCurrentInstance } from 'vue'
import { useQueryView } from '@/hooks/useQueryView'
import { getTypeName } from '@/utils'
import axios from '@/plugins/axios'
import moment from 'moment'
import { getOpsLineList, getOpsTypeList } from '@/service/getData'

export default defineComponent({
  setup() {
    const vm = getCurrentInstance().proxy
    const getListPage = async (pagination, fields) => {
      const res = await axios.post('/ops-server/opsTemplate/list', {
        pageSize: pagination.pageSize,
        pageNo: pagination.pageNo,
        ...fields,
      })
      return {
        dataSource: res.data.data,
        total: res.data.totalCount,
      }
    }
    const typesMap = {
      status: [
        {
          label: '未验证',
          value: 1,
        },
        {
          label: '可用',
          value: 2,
        },
        {
          label: '无效',
          value: 3,
        },
      ],
    }
    const formRef = ref(null)
    const { pageSize, pageNo, total, dataSource, loading, getRowKey, resetAndQuery, doQuery, handleTableChange } =
      useQueryView(formRef, {
        getListPage,
        onReset() {
          vm.opsTypeList = []
        },
      })
    return {
      formRef,
      pageSize,
      pageNo,
      total,
      dataSource,

      loading,
      getRowKey,
      resetAndQuery,
      doQuery,
      handleTableChange,

      typesMap,
      getTypeName,
    }
  },
  data() {
    return {
      columns: [
        {
          title: '条线',
          dataIndex: 'opsLine',
          width: '10%',
        },
        {
          title: '类型',
          width: '10%',
          dataIndex: 'opsType',
        },
        {
          title: '服务名称',
          dataIndex: 'opsName',
        },
        {
          title: '发布人员',
          width: '10%',

          dataIndex: 'publishPersonnel',
        },
        {
          title: '发布日期',
          width: '10%',

          dataIndex: 'publishTime',
          customRender: (text) => {
            return <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
          },
        },
        {
          title: '验证状态',
          width: '10%',

          dataIndex: 'status',
          customRender: (text) => {
            return (
              <span
                class={{
                  'text-gray-400': text === 3,
                  'text-green-500': text === 2,
                }}
              >
                {getTypeName(text, this.typesMap.status)}
              </span>
            )
          },
        },
        {
          title: '操作',
          width: 150,
          customRender: (text, row) => {
            return (
              <div class="flex gap-3">
                {row.status === 2 && (
                  <fragment>
                    <a
                      class="text-blue-500"
                      onClick={() => {
                        this.opRow(row)
                      }}
                    >
                      使用
                    </a>
                    <a
                      class="text-gray-400"
                      onClick={() => {
                        this.disableRow(row)
                      }}
                    >
                      失效
                    </a>
                  </fragment>
                )}
                {[1, 3].includes(row.status) && (
                  <fragment>
                    <a
                      class="text-blue-500"
                      onClick={() => {
                        this.checkRow(row)
                      }}
                    >
                      修改
                    </a>
                    <a
                      class="text-red-500"
                      onClick={() => {
                        this.auditRow(row)
                      }}
                    >
                      审核
                    </a>
                  </fragment>
                )}
              </div>
            )
          },
        },
      ],
      form: this.$form.createForm(this),
      opsLineList: [],
      opsTypeList: [],
    }
  },
  methods: {
    addRow() {
      this.$router.push({
        path: '/ops/serviceDetail',
      })
    },
    checkRow(row) {
      this.$router.push({
        path: '/ops/serviceDetail',
        query: {
          id: row.id,
        },
      })
    },
    opRow(row) {
      this.$router.push({
        path: '/ops/serviceOp',
        query: {
          id: row.id,
        },
      })
    },
    async disableRow(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '操作确认',
          content: '是否失效记录?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/ops-server/opsTemplate/put', {
        id: row.id,
        status: 3,
      })
      this.doQuery()
      this.$message.success('操作成功')
    },
    auditRow(row) {
      this.$router.push({
        path: '/ops/serviceDetail',
        query: {
          id: row.id,
          type: 'audit',
        },
      })
    },
    async onSelectOpsLine(lineCode) {
      this.form.setFieldsValue({ typeCode: undefined })
      this.opsTypeList = await getOpsTypeList(lineCode)
    },
  },
  async mounted() {
    this.opsLineList = await getOpsLineList()
  },
})
</script>
<style lang="less"></style>
